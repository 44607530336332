<script setup>
import { onMounted, onBeforeUnmount, computed, inject, ref, watch, nextTick } from "vue"

import { Observer } from "gsap/Observer"
import { useMain } from "@/Stores/main.js"

const gsap = inject("gsap")
const mainStore = useMain()
gsap.registerPlugin(Observer)
const props = defineProps({
	slides: {
		type: Array
	},
	settings: Object
})

const currentIndex = ref(-1)
const intentObserver = ref()
watch(currentIndex, newValue => {
	if (newValue + 1 === props.slides.length) {
		mainStore.showNavbar = true
	}
})
onMounted(() => {
	let sections = document.querySelectorAll("section"),
		images = document.querySelectorAll(".bg"),
		outer = gsap.utils.toArray(".outer"),
		inner = gsap.utils.toArray(".inner")
	let wrap = gsap.utils.wrap(0, sections.length)
	let animating
	gsap.set(outer, { yPercent: 100 })
	gsap.set(inner, { yPercent: -100 })

	function gotoSection(index, direction) {
		index = wrap(index)
		animating = true
		let fromTop = direction === -1,
			dFactor = fromTop ? -1 : 1,
			tl = gsap.timeline({
				defaults: { duration: 1.25, ease: "power1.inOut" },
				onComplete: () => (animating = false)
			})
		if (currentIndex.value >= 0) {
			// The first time this function runs, current is -1
			gsap.set(sections[currentIndex.value], { zIndex: 0 })
			tl.to(images[currentIndex.value], { yPercent: -15 * dFactor }).set(sections[currentIndex.value], { autoAlpha: 0 })
		}
		// if (currentIndex.value >= 0) {
		// 	gsap.set(sections[currentIndex.value], [currentIndex.value], { zIndex: 0 })
		// }
		gsap.set(sections[index], { autoAlpha: 1, zIndex: 1 })
		tl.fromTo(
			[outer[index], inner[index]],
			{
				yPercent: i => (i ? -100 * dFactor : 100 * dFactor)
			},
			{
				yPercent: 0
			},
			0
		)
		currentIndex.value = index
	}

	// }

	intentObserver.value = Observer.create({
		type: "wheel,touch",
		wheelSpeed: -1,
		onDown: () => !animating && gotoSection(currentIndex.value - 1, -1),
		onUp: () => !animating && gotoSection(currentIndex.value + 1, 1),
		tolerance: 10,
		preventDefault: true
	})

	gotoSection(0, 1)
})

const getWindowHeight = computed(() => window.screen.height)
</script>

<template>
	<section v-for="(slide, index) in slides" :key="index" :class="{ active: currentIndex === index }" class="section invisible fixed top-0 h-screen w-full">
		<div class="outer">
			<div class="inner">
				<div v-if="slide" :style="`background-image: url(/storage/${slide.image})`" class="absolute top-0 h-full w-full bg-cover bg-left bg-no-repeat md:bg-center">
					<div class="w-container relative z-30 flex h-full w-full items-end pb-32">
						<div class="space-y-4 md:max-w-md lg:max-w-2xl 2xl:max-w-3xl">
							<p v-if="slide.subtitle" class="animation section-subtitle relative text-xl font-semibold text-white">
								<span class="animation">{{ slide.subtitle }}</span>
								<span class="animation width-to-right absolute -left-20 top-1/2 hidden h-[2px] -translate-y-1/2 bg-white md:block"></span>
							</p>
							<a v-if="slide.title" :href="slide.url ? slide.url : 'javascript:void(0)'" class="animation to-top heading-2xl block text-white underline" target="_blank">
								{{ slide.title }}
							</a>
							<a v-if="slide.url" :href="slide.url ? slide.url : 'javascript:void(0)'" class="animation to-top block text-white underline" target="_blank">
								{{ $t("Read more") }}
							</a>
							<p v-if="slide.excerpt" class="animation to-top leading-5 text-white" v-html="slide.excerpt"></p>
						</div>
					</div>
					<div v-if="settings?.additionally?.dimming_level" :style="`opacity: ${settings?.additionally?.dimming_level}`" class="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
.outer,
.inner {
	@apply h-full w-full overflow-y-hidden;
}

.section {
	.animation {
		@apply invisible opacity-0 transition-all delay-[1500ms] duration-500 ease-in-out;

		&.to-top {
			@apply translate-y-10;
		}

		&.width-to-right {
			@apply w-0 transition-all delay-500 duration-300 ease-in-out;
		}
	}

	&.active {
		.animation {
			@apply visible opacity-100 delay-[1500ms];

			&.to-top {
				@apply translate-y-0;
			}

			&.width-to-right {
				@apply w-16;
			}
		}
	}
}
</style>
